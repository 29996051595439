import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { HandledDetails } from 'src/types/HandledDetails';
import { HandledInvoiceDrawerTab } from 'src/types/tabs/HandledInvoiceDrawerTab';
import HandledInvoiceDetailsTab from '../HandledInvoiceDetailsTab';
import RejectionDetailsTab from 'src/components/Economy/RejectedInvoicesTab/RejectiedInvoiceDrawer/RejectionDetailsTab';
import { RejectionDetail } from 'src/types/RejectionDetail';

interface HandledDetailsDrawerOverivewProps {
  invoice: Invoice;
  handledDetails: HandledDetails[];
  onClose: () => void;
  onSelectedHandledDetails: (id: string) => void;
  onSelectedRejectedDetails: (id: string) => void;
  currentTab: HandledInvoiceDrawerTab;
  rejectionDetails: RejectionDetail[];
}

const HandledDetailsDrawerOverivew: FC<HandledDetailsDrawerOverivewProps> = ({
  invoice,
  handledDetails,
  onClose,
  onSelectedHandledDetails,
  onSelectedRejectedDetails,
  currentTab,
  rejectionDetails,
}) => {
  const { t } = useTranslation();
  return (
    <DrawerBase
      onClose={onClose}
      title={t('economy:handled-invoices-drawer.drawer-title') + ` ${invoice?.invoiceNumber}`}
      subTitle={
        <div className="handled-invoices-drawer__sub-title">
          <Typography>{invoice.employer.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      }
      data-testid="handled-invoices-drawer-overview-id"
    >
      <Tabs tabIndex={currentTab}>
        <Tab title={t('economy:handled-invoices-drawer.handled-details-drawer-tab.tab-label')}>
          <HandledInvoiceDetailsTab onSelectHandledDetail={onSelectedHandledDetails} handledDetails={handledDetails} />
        </Tab>
        <Tab title={t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.tab-label')}>
          <RejectionDetailsTab
            onSelectRejectionDetail={onSelectedRejectedDetails}
            rejectionDetails={rejectionDetails}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default HandledDetailsDrawerOverivew;
