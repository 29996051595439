import { Button, Drawer, Icon, InlineEdit, ListGroup, Option, Pill, Select, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { useCreateDocumentScheduling } from 'src/service/DocumentScheduling';
import TextInput from 'src/components/Common/TextInput';
import { CreateSchedulingInput } from 'src/types/documenttemplate/CreateSchedulingInput';
import { InputParameterType, ReportingPeriodType } from 'src/types/documenttemplate/DocumentScheduling';
import { getInputParametersOptions } from './DocumentTemplateUtils';
import './add-scheduling.scss';

interface AddSchedulingProps {
  assignment: DocumentTemplateEmployerAssignment;
  customerId: string;
  onClose: () => void;
  onBackClick: () => void;
}

const AddScheduling: FC<AddSchedulingProps> = ({ assignment, customerId, onClose, onBackClick }) => {
  const { t } = useTranslation();
  const createDocumentScheduling = useCreateDocumentScheduling();

  const [input, setInput] = useState<CreateSchedulingInput>({
    templateId: assignment.templateId,
    reportingPeriod: null,
    triggerCron: '',
    formatType: assignment.formatType,
    inputParameter: null,
  });

  const inputParameterOptions = getInputParametersOptions(assignment?.inputParameters);

  const reportingPeriodOptions: Option<ReportingPeriodType>[] = [
    { label: t(`documents:reporting-period.${'LAST_MONTH'}`), value: 'LAST_MONTH' },
    { label: t(`documents:reporting-period.${'CURRENT_MONTH'}`), value: 'CURRENT_MONTH' },
    { label: t(`documents:reporting-period.${'FIRST_QUARTER'}`), value: 'FIRST_QUARTER' },
    { label: t(`documents:reporting-period.${'SECOND_QUARTER'}`), value: 'SECOND_QUARTER' },
    { label: t(`documents:reporting-period.${'THIRD_QUARTER'}`), value: 'THIRD_QUARTER' },
    { label: t(`documents:reporting-period.${'FOURTH_QUARTER'}`), value: 'FOURTH_QUARTER' },
  ];

  const handleSelectInputParameter = (option: Option<InputParameterType>) => {
    setInput({ ...input, inputParameter: option.value });
  };

  const handleTriggerCronInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, triggerCron: event.target.value });
  };

  const handleReportingPeriodChange = (option: Option<ReportingPeriodType>) => {
    setInput({ ...input, reportingPeriod: option.value });
  };

  const handleCreateScheduling = () => {
    createDocumentScheduling(input, assignment.employer.id, customerId).then(() => onBackClick());
  };
 
  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="template-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {assignment.templateName}
        </Typography>
        <div className="template-drawer__sub-title">
          <Typography>{assignment.employer.legalName}</Typography>
          <Pill label={t(`documents:format-type.${assignment.formatType}`)} />
        </div>
      </div>

      <div className="add-scheduling">
        <Typography variant="body1" bold>
          {t('documents:templates-tab.drawer.scheduling-drawer-tab.add-scheduling-label')}
        </Typography>
      </div>

      <div className="add-scheduling__list-group">
        <ListGroup variant="inline-edit">
          <InlineEdit
            label={t('documents:templates-tab.drawer.scheduling-drawer-tab.trigger-cron-label')}
            bold
            mandatory
          >
            <TextInput
              placeholder={t('documents:templates-tab.drawer.scheduling-drawer-tab.trigger-cron-placeholder')}
              type="text"
              validationKey="text64"
              maxLength={65}
              variant="default"
              value={input.triggerCron}
              onChange={handleTriggerCronInput}
              mandatory
            />
          </InlineEdit>
          <InlineEdit
            label={t('documents:templates-tab.drawer.scheduling-drawer-tab.reporting-period-label')}
            mandatory
            bold>
            <Select
              mandatory
              outlined
              small
              placeholder={t('documents:templates-tab.drawer.scheduling-drawer-tab.reporting-period-placeholder')}
              options={reportingPeriodOptions}
              selected={reportingPeriodOptions.find((option) => option.value === input.reportingPeriod)}
              onChange={handleReportingPeriodChange}
            />
          </InlineEdit>
          <When condition={assignment.formatType === 'EXCEL' && assignment.inputParameters.length > 0}>
            <ListGroup variant="inline-edit">
              <InlineEdit
                label={t('documents:templates-tab.drawer.scheduling-drawer-tab.period-selection-label')}
                mandatory
                bold
              >
                <Select
                  mandatory
                  outlined
                  small
                  placeholder={t(
                    'documents:templates-tab.drawer.scheduling-drawer-tab.period-selection-placeholder',
                  )}
                  options={inputParameterOptions}
                  selected={inputParameterOptions?.find((option) => option?.value === input?.inputParameter)}
                  onChange={handleSelectInputParameter}
                  data-testid="dropdown-select-input-parameter"
                />
              </InlineEdit>
            </ListGroup>
          </When>
        </ListGroup>
      </div>
      <div className="add-scheduling__buttons">
        <Button
          className="add-scheduling__button"
          label={t('common:add')}
          disabled={input.triggerCron.length < 9 || !input.reportingPeriod || (assignment.inputParameters.length > 0 && !input.inputParameter)}
          onClick={handleCreateScheduling}
        />
      </div>
    </Drawer>
  );
};

export default AddScheduling;
