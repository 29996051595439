import { SearchRejectedInvoicesRequest } from 'src/types/SearchRejectedInvoicesRequest';

export const getInitialRejectedInvoicesRequest = (): SearchRejectedInvoicesRequest => {
  return {
    pageNumber: 0,
    pageSize: 20,
    filters: {
      rejectionCreatedDateFilter: {
        startDate: '',
        endDate: '',
      },
      rejectedByFilter: null,
    },
  };
};
