import React, { FC, useState } from 'react';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Invoice, InvoiceStatusEnum } from 'src/types/Invoice';
import { SearchRejectedInvoicesFilters, SearchRejectedInvoicesRequest } from 'src/types/SearchRejectedInvoicesRequest';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Paging } from 'src/types/PageableResponse';
import RejectedInvoicesFilterDetails from '../RejectedInvoicesFilterDetails';
import RejectedInvoicesFiltersModal from '../RejectedInvoicesFiltersModal';
import RejectedInvoicesTable from '../RejectedInvoicesTable';
import { usePatchInvoiceHeader } from 'src/service/PatchInvoiceHeader';
import RejectedInvoicesConfirmModal from '../RejectedInvoicesConfirmModal';

interface RejectedInvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  searchRejectedInvoicesRequest: SearchRejectedInvoicesRequest;
  onPageChange: (nextPageNumber: number) => void;
  onApplyFilters: (filters: SearchRejectedInvoicesFilters) => void;
}

const RejectedInvoices: FC<RejectedInvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  searchRejectedInvoicesRequest,
  onPageChange,
  onApplyFilters,
}) => {
  const { t } = useTranslation();
  const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>();
  const [checked, setChecked] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  const [handledInvoice, setHandledInvoice] = useState<string[]>([]);

  const handleCloseModal = () => setIsFiltersModalOpen(false);
  const handleModalOpen = () => setIsFiltersModalOpen(true);
  const handleDrawerOpen = (id: string) => setSelectedInvoiceId(id);
  const patchInvoiceHeader = usePatchInvoiceHeader();

  const toggleChecked = (id: string) => {
    if (checked.includes(id)) {
      setChecked((prev) => prev.filter((c) => c !== id));
    } else {
      setChecked((prev) => [...prev, id]);
    }
  };

  const toggleAll = () => {
    if (checked.length === invoices.length && invoices.every((invoice) => checked.includes(invoice.id))) {
      setChecked([]);
    } else {
      setChecked(invoices.map((invoice) => invoice.id));
    }
  };

  const handleHandleClick = () => {
    if (checked.length > 0) {
      setSelectedInvoiceIds(checked);
      setIsModalOpen(true);
    }
  };

  const patchInvoiceHeaderStatus = (status: string, note: string) => {
    const invoicesToPatch = checked.map((invoiceId) => ({
      invoiceHeaderId: invoiceId,
      invoiceStatus: status,
      handledDetails: [{ note }],
    }));

    const groupedByEmployer = invoicesToPatch.reduce((acc, invoice) => {
      const employerId = invoices.find((i) => i.id === invoice.invoiceHeaderId)?.employer.id;
      if (!acc[employerId]) acc[employerId] = [];
      acc[employerId].push(invoice);
      return acc;
    }, {} as Record<string, typeof invoicesToPatch>);

    Object.entries(groupedByEmployer).forEach(([employerId, invoicesForEmployer]) => {
      patchInvoiceHeader(invoicesForEmployer, employerId);
    });

    if (status === InvoiceStatusEnum.HANDLED) {
      setHandledInvoice((prev) => [...prev, ...checked]);
    } else {
      setHandledInvoice((prev) => prev.filter((invoiceId) => !checked.includes(invoiceId)));
    }

    setChecked([]);
  };

  const handleConfirm = async (note: string) => {
    patchInvoiceHeaderStatus(InvoiceStatusEnum.HANDLED, note);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="rejected-invoices-tab">
        <RejectedInvoicesFilterDetails searchRequest={searchRejectedInvoicesRequest} applyFilters={onApplyFilters} />
        <div className="rejected-invoices-tab__mark-button">
          <Button type="primary" label={t('economy:rejected-invoices-tab.buttons.mark-button')} onClick={toggleAll} />
        </div>
        <div className="rejected-invoices-tab__table-buttons">
          <Button
            type="primary"
            label={t('economy:rejected-invoices-tab.buttons.handle-button')}
            onClick={handleHandleClick}
            disabled={checked.length === 0}
          />
        </div>
        <RejectedInvoicesTable
          invoices={invoices}
          onRowClick={handleDrawerOpen}
          checked={checked}
          onChange={toggleChecked}
        />
        {totalPages > 1 && <Pagination currentPage={pageNumber} onPageChange={onPageChange} totalPages={totalPages} />}
        {isFiltersModalOpen && (
          <RejectedInvoicesFiltersModal
            open={isFiltersModalOpen}
            onClose={handleCloseModal}
            onApplyFilters={onApplyFilters}
            filters={searchRejectedInvoicesRequest.filters}
          />
        )}
        {isModalOpen && (
          <RejectedInvoicesConfirmModal open={isModalOpen} onConfirm={handleConfirm} onClose={handleCancel} />
        )}

        <div className="rejected-invoices-tab__filter-button">
          <Button
            type="floating-action-button"
            icon={<Icon type="Sliders" stroke="white" />}
            label={t('common:filter')}
            onClick={handleModalOpen}
            data-cy="filter-button"
          />
        </div>
      </div>
    </>
  );
};

export default RejectedInvoices;
