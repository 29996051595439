import { gql } from '@apollo/client';

export const GET_HANDLED_DETAILS = gql`
  query InternalGetHandledDetails($invoiceHeaderId: UUID!) {
    internalHandledDetails(invoiceHeaderId: $invoiceHeaderId) {
      id
      createdDate
      note
    }
  }
`;
