import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchRejectedInvoicesFilters, SearchRejectedInvoicesRequest } from 'src/types/SearchRejectedInvoicesRequest';
import './rejected-invoices-filter-details.scss';

interface RejectedInvoicesFilterDetailsProps {
  searchRequest: SearchRejectedInvoicesRequest;
  applyFilters: (filters: SearchRejectedInvoicesFilters) => void;
}

export const RejectedInvoicesFilterDetails: FC<RejectedInvoicesFilterDetailsProps> = ({
  searchRequest,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const filtersApplied =
    searchRequest.filters?.rejectionCreatedDateFilter?.startDate?.length > 0 ||
    searchRequest.filters?.rejectionCreatedDateFilter?.endDate?.length > 0 ||
    searchRequest.filters?.rejectedByFilter?.length > 0;

  const removeRejectionCreatedStartDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      rejectionCreatedDateFilter: {
        ...searchRequest.filters?.rejectionCreatedDateFilter,
        startDate: '',
      },
    });
  };

  const removeRejectionCreatedEndDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      rejectionCreatedDateFilter: {
        ...searchRequest.filters?.rejectionCreatedDateFilter,
        endDate: '',
      },
    });
  };

  const removeRejectedByFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      rejectedByFilter: null,
    });
  };

  return (
    <div className="rejected-invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {searchRequest.filters?.rejectionCreatedDateFilter?.startDate?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.rejectionCreatedDateFilter?.startDate +
                ' ' +
                t('economy:search-rejected-invoices-filters.rejection-created-start-date')
              }
              onClick={removeRejectionCreatedStartDateFilter}
            />
          )}
          {searchRequest.filters?.rejectionCreatedDateFilter?.endDate?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.rejectionCreatedDateFilter?.endDate +
                ' ' +
                t('economy:search-rejected-invoices-filters.rejection-created-end-date')
              }
              onClick={removeRejectionCreatedEndDateFilter}
            />
          )}
          {searchRequest.filters?.rejectedByFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.rejectedByFilter?.length +
                ' ' +
                t('economy:search-rejected-invoices-filters.rejected-by')
              }
              onClick={removeRejectedByFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RejectedInvoicesFilterDetails;
