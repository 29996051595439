import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountingBalanceTab from 'src/components/Economy/AccountingBalanceTab';
import InvoicesTab from 'src/components/Economy/InvoicesTab';
import PaymentsTab from 'src/components/Economy/PaymentsTab';
import RejectedInvoicesTab from 'src/components/Economy/RejectedInvoicesTab';
import PageBase from 'src/components/PageBase';
import useTabParams from 'src/hooks/useTabParams';
import { EconomyTab } from 'src/types/tabs/EconomyTab';
import './economy.scss';
import HandledInvoicesTab from 'src/components/Economy/HandledInvoicesTab';

const Economy = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', EconomyTab.INVOICES);

  return (
    <PageBase title={t('economy:title')} header={true}>
      <Tabs className="economy" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('economy:tabs.invoices-tab')}>
          <InvoicesTab />
        </Tab>
        <Tab title={t('economy:tabs.payments-tab')}>
          <PaymentsTab />
        </Tab>
        <Tab title={t('economy:tabs.rejected-invoices-tab')}>
          <RejectedInvoicesTab />
        </Tab>
        <Tab title={t('economy:tabs.accounting-balance-tab')}>
          <AccountingBalanceTab />
        </Tab>
        <Tab title={t('economy:tabs.handled-invoices-tab')}>
          <HandledInvoicesTab />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default Economy;
