import { gql } from '@apollo/client';

export const GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS = gql`
  query InternalDocumentTemplateEmployerAssignments($employerId: UUID!) {
    internalDocumentTemplateEmployerAssignments(employerId: $employerId) {
      id
      templateId
      templateName
      formatType
      inputParameters
      inputPeriodParameter
      employer {
        id
        legalName
      }
      scheduledJobs {
        id
        triggerCron
        templateId
        reportingPeriod
        formatType
        inputParameter
      }
    }
  }
`;
