import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './handled-invoices-table-row.scss';

interface HandledInvoicesTableRowProps {
  invoice: Invoice;
  onRowClick: (id: string) => void;
}

const HandledInvoicesTableRow: FC<HandledInvoicesTableRowProps> = ({ invoice, onRowClick }) => {
  const { t } = useTranslation();

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'INPUT' || target.closest('label')) {
      return;
    }

    onRowClick(invoice.id);
  };

  return (
    <TableRow
      className="handled-invoices-table-row"
      onClick={handleRowClick}
      data-testid={`handled-invoices-table-row__${invoice.id}`}
    >
      <Td compressed>{stringOrBlank(invoice.employer?.legalName)}</Td>
      <Td compressed>{stringOrBlank(invoice.fileName)}</Td>
      <Td compressed>{stringOrBlank(invoice.insuranceProvider?.legalName)}</Td>
      <Td compressed>{stringOrBlank(invoice.accountNumberTo)}</Td>
      <Td compressed>{stringOrBlank(invoice.invoiceNumber)}</Td>
      <Td compressed>{formatSweAmountText(`${invoice.totalAmount}`)}</Td>
      <Td compressed>{t(`invoice:invoice-type-code.${invoice.invoiceTypeCode}`)}</Td>
      <Td compressed>{stringOrBlank(invoice.dueDate)}</Td>
      <Td compressed>{stringOrBlank(invoice.paymentDueDate)}</Td>
      <Td compressed>{stringOrBlank(invoice.ocrNumber)}</Td>
      <Td compressed>{stringOrBlank(invoice.createdAt.substring(0, 10))}</Td>
    </TableRow>
  );
};

export default HandledInvoicesTableRow;
