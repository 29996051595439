import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SearchRejectedInvoicesPage } from 'src/types/Invoice';
import { SearchRejectedInvoicesFilters, SearchRejectedInvoicesRequest } from 'src/types/SearchRejectedInvoicesRequest';
import { SEARCH_REJECTED_INVOICES } from 'src/graphql/schema/query/SearchRejectedInvoices';
import { getInitialRejectedInvoicesRequest } from './utils/rejectedInvoiceUtils';
import RejectedInvoices from './RejectedInvoices';
import './rejected-invoices-tab.scss';

const RejectedInvoicesTab = () => {
  const [searchRejectedInvoicesRequest, setSearchRejectedInvoicesRequest] = useState<SearchRejectedInvoicesRequest>(
    getInitialRejectedInvoicesRequest(),
  );

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleApplyFilters = (filters: SearchRejectedInvoicesFilters) => {
    setSearchRejectedInvoicesRequest({ ...searchRejectedInvoicesRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_REJECTED_INVOICES}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: { pageNumber: queryPageNumber, pageSize, filters: searchRejectedInvoicesRequest.filters },
        },
      }}
    >
      {({ internalSearchRejectedInvoices, paging }: SearchRejectedInvoicesPage) => (
        <RejectedInvoices
          invoices={internalSearchRejectedInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          searchRejectedInvoicesRequest={searchRejectedInvoicesRequest}
          onPageChange={handleOnPageChange}
          onApplyFilters={handleApplyFilters}
        />
      )}
    </QueryWrapper>
  );
};

export default RejectedInvoicesTab;
