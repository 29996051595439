import { CREATE_SCHEDULING } from 'src/graphql/schema/mutation/documenttemplate/CreateScheduling';
import { DELETE_DOCUMENT_SCHEDULING } from 'src/graphql/schema/mutation/documenttemplate/DeleteDocumentScheduling';
import { GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS } from 'src/graphql/schema/query/documenttemplate/GetDocumentTemplateEmployerAssignments';
import { CreateSchedulingInput } from 'src/types/documenttemplate/CreateSchedulingInput';
import { useApolloMutation } from './graphql/Mutation';

export const useCreateDocumentScheduling = () => {
  const apolloMutation = useApolloMutation();

  const createDocumentScheduling = (request: CreateSchedulingInput, employerId: string, customerId: string) =>
    apolloMutation(CREATE_SCHEDULING, employerId, { request }, [GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS], customerId);

  return createDocumentScheduling;
};

export const useDeleteDocumentScheduling = () => {
  const apolloMutation = useApolloMutation();

  const deleteDocumentScheduling = (jobId: string, employerId: string) =>
    apolloMutation(DELETE_DOCUMENT_SCHEDULING, employerId, { jobId }, [GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS]);

  return deleteDocumentScheduling;
};
