import React, { ChangeEvent, useState } from 'react';
import { Icon } from 'fgirot-k2-ui-components';
import TextInput from 'src/components/Common/TextInput';

interface SearchInputProps {
  placeHolder: string;
  searchInput: string;
  type: 'number' | 'text';
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const SearchInput = ({ onSearch, disabled, searchInput, type, placeHolder }: SearchInputProps) => {
  const [input, setInput] = useState<string>(searchInput);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchInput !== event.target.value) {
      onSearch(input);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(input);
    } else if (e.key === 'Escape') {
      setInput('');
    }
  };

  return (
    <TextInput
      variant="default"
      value={input}
      disabled={disabled}
      placeholder={placeHolder}
      type={type}
      icon={<Icon type="Search" />}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
    />
  );
};

export default SearchInput;
