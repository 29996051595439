import { SortDirection, TableRow, Th, ThProps } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { HandledInvoicesTableSortField } from './HandledInvoicesTableSortField';
import { FC } from 'react';

interface HandledInvoicesTableHeadsProps {
  sortDirection: SortDirection;
  sortField: HandledInvoicesTableSortField;
  onApplySorting(property: HandledInvoicesTableSortField);
}

export interface TableHeadProps extends ThProps {
  title: string;
  fieldName: string;
}

const HandledInvoicesTableHead: FC<HandledInvoicesTableHeadsProps> = ({ sortDirection, sortField, onApplySorting }) => {
  const { t } = useTranslation();

  const tableHeads: TableHeadProps[] = [
    {
      title: t('economy:handled-invoices-tab.table-head.employer'),
      fieldName: 'employer',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.file-name'),
      fieldName: 'fileName',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.insurance-provider'),
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.account-to'),
      fieldName: 'accountNumberTo',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.invoice-number'),
      fieldName: 'invoiceNumber',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.amount'),
      sortDirection,
      fieldName: 'totalAmount',
      handleSortDirectionChange: () => {
        onApplySorting('totalAmount');
      },
    },
    {
      title: t('economy:handled-invoices-tab.table-head.invoice-type-code'),
      fieldName: 'invoiceTypeCode',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.invoice-due-date'),
      sortDirection,
      fieldName: 'dueDate',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceDueDate');
      },
    },
    {
      title: t('economy:handled-invoices-tab.table-head.payment-due-date'),
      sortDirection,
      fieldName: 'paymentDueDate',
      handleSortDirectionChange: () => {
        onApplySorting('paymentDueDate');
      },
    },
    {
      title: t('economy:handled-invoices-tab.table-head.ocr-number'),
      fieldName: 'ocrNumber',
    },
    {
      title: t('economy:handled-invoices-tab.table-head.created-date'),
      fieldName: 'createdAt',
      sortDirection,
      handleSortDirectionChange: () => {
        onApplySorting('createdDate');
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head, index) => {
        return (
          <Th
            includeSortButtons={head.sortDirection != undefined}
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`handled-invocie-table-head-${index}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default HandledInvoicesTableHead;
