import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { useDeleteDocumentScheduling } from 'src/service/DocumentScheduling';
import { DocumentScheduling } from 'src/types/documenttemplate/DocumentScheduling';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import './scheduling-details.scss';

interface SchedulingDetailsProps {
  assignment: DocumentTemplateEmployerAssignment;
  scheduling: DocumentScheduling;
  onClose: () => void;
  onBackClick: () => void;
}

const SchedulingDetails: FC<SchedulingDetailsProps> = ({ assignment, scheduling, onClose, onBackClick }) => {
  const { t } = useTranslation();

  const deleteDocumentScheduling = useDeleteDocumentScheduling();

  const handleDeleteScheduling = () => {
    deleteDocumentScheduling(scheduling.id, assignment.employer.id).then(() => onBackClick());
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="template-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {`${assignment.templateName}`}
        </Typography>
        <div className="template-drawer__sub-title">
          <Typography>{assignment.templateName}</Typography>
          <Pill label={t(`documents:format-type.${assignment.formatType}`)} />
        </div>
      </div>

      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('documents:templates-tab.drawer.scheduling-drawer-tab.trigger-cron-label')}
          value={scheduling.triggerCron}
        />
        <ListProperty
          label={t('documents:templates-tab.drawer.scheduling-drawer-tab.reporting-period-label')}
          value={t(`documents:reporting-period.${scheduling.reportingPeriod}`)}
        />
        <ListProperty
          label={t('documents:templates-tab.drawer.scheduling-drawer-tab.period-selection-label')}
          value={t(`documents:input-parameters.${scheduling.inputParameter}`)}
        />
      </ListGroup>
      <div className="scheduling-details__buttons">
        <Button
          className="scheduling-details__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          label={t('common:remove')}
          onClick={handleDeleteScheduling}
          data-cy="scheduling-details__delete-button"
        />
      </div>
    </Drawer>
  );
};

export default SchedulingDetails;
