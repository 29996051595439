import { SearchHandledInvoicesFilter } from 'src/types/invoice/SearchHandledInvoicesFilter';

export const getDefaultHandledInvoicesFilters = (): SearchHandledInvoicesFilter => {
  return {
    searchInput: '',
    invoiceDueDate: {
      fromDate: '',
      toDate: '',
    },
    invoiceStatuses: ['HANDLED'],
    createdDate: {
      from: { date: '', time: '' },
      to: { date: '', time: '' },
    },
  };
};
