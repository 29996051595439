export interface DocumentTemplate {
  id: string;
  name: string;
  description: string;
  formatType: FormatType;
}

export type FormatType = 'EXCEL' | 'PDF' | 'CSV' | 'ACCOUNTING';

export type InputParameter = 'INPUT_PERIODTYPE_ACCOUNTING' | 'INPUT_PERIODTYPE_PREMIUM' | 'INPUT_PERIODTYPE_PAYMENT';

export enum InputParameterEnum {
  INPUT_PERIODTYPE_ACCOUNTING = 'INPUT_PERIODTYPE_ACCOUNTING',
  INPUT_PERIODTYPE_PREMIUM = 'INPUT_PERIODTYPE_PREMIUM',
  INPUT_PERIODTYPE_PAYMENT = 'INPUT_PERIODTYPE_PAYMENT',
}
