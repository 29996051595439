import { Button, Card, CardAction, Icon, Modal } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { SearchHandledInvoicesFilter } from 'src/types/invoice/SearchHandledInvoicesFilter';
import './handled-invoices-filters-modal.scss';
import TextInput from 'src/components/Common/TextInput';
import { isBlank } from 'src/util/StringUtil/StringUtil';

interface HandledInvoicesFiltersModalProps {
  open: boolean;
  filters: SearchHandledInvoicesFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchHandledInvoicesFilter) => void;
}

const HandledInvoicesFiltersModal: FC<HandledInvoicesFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();
  const [newFilters, setNewFilters] = useState<SearchHandledInvoicesFilter>({ ...filters });

  const handleFromInvoiceDueDateChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      invoiceDueDate: {
        fromDate: value,
        toDate: newFilters.invoiceDueDate.toDate,
      },
    });
  };

  const handleToInvoiceDueDateChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      invoiceDueDate: {
        fromDate: newFilters.invoiceDueDate.fromDate,
        toDate: value,
      },
    });
  };

  const handleFromCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: { ...newFilters.createdDate.from, date: value },
        to: newFilters.createdDate.to,
      },
    });
  };

  const handleToCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: newFilters.createdDate.from,
        to: { ...newFilters.createdDate.to, date: value },
      },
    });
  };

  const handleToCreatedTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: newFilters.createdDate.from,
        to: { ...newFilters.createdDate.to, time: input?.target.value },
      },
    });
  };

  const handleFromCreatedTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: { ...newFilters.createdDate.from, time: input?.target.value },
        to: newFilters.createdDate.to,
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="handled-invoices.filters-modal"
        title={t('economy:handled-invoices-filters-modal.title')}
        icon={<Icon type="Sliders" className="handled-invoices-filters-modal__icon" />}
        size="wide"
      >
        <div className="handled-invoices-filters-modal__filters">
          <div className="handled-invoices-filters-modal__date-section">
            <UpdateDatePicker
              label={t('economy:handled-invoices-filters-modal.from-invoice-due-date-label')}
              fieldValue={newFilters?.invoiceDueDate?.fromDate ?? ''}
              fieldKey="invoiceDueDate"
              onBlur={(_, value) => {
                handleFromInvoiceDueDateChange(value);
              }}
            />
            <UpdateDatePicker
              label={t('economy:handled-invoices-filters-modal.to-invoice-due-date-label')}
              fieldValue={newFilters?.invoiceDueDate?.toDate ?? ''}
              fieldKey="invoiceDueDate"
              onBlur={(_, value) => {
                handleToInvoiceDueDateChange(value);
              }}
            />
          </div>
        </div>

        <div className="handled-invoices-filters-modal__date-time-interval">
          <div className="handled-invoices-filters-modal__row">
            <div className="handled-invoices-filters-modal__date-section">
              <UpdateDatePicker
                label={t('economy:handled-invoices-filters-modal.from-created-date-label')}
                fieldValue={newFilters?.createdDate?.from?.date?.toString()}
                fieldKey="createdDate"
                onBlur={handleFromCreatedDateFilterChange}
              />
            </div>
            <div className="handled-invoices-filters-modal__time-section">
              <TextInput
                label={t('economy:handled-invoices-filters-modal.from-created-time-label')}
                type="text"
                variant="default"
                validationKey="hourWithMinutes"
                placeholder={t('economy:handled-invoices-filters-modal.created-date-time-placeholder')}
                onChange={handleFromCreatedTimeFilterChange}
                value={newFilters?.createdDate?.from?.time}
                disabled={isBlank(newFilters?.createdDate.from?.date)}
                maxLength={5}
              />
            </div>
          </div>
        </div>

        <div className="handled-invoices-filters-modal__date-time-interval">
          <div className="handled-invoices-filters-modal__row">
            <div className="handled-invoices-filters-modal__date-section">
              <UpdateDatePicker
                label={t('economy:handled-invoices-filters-modal.to-created-date-label')}
                fieldValue={newFilters?.createdDate?.to?.date?.toString()}
                fieldKey="createdDate"
                onBlur={handleToCreatedDateFilterChange}
              />
            </div>
            <div className="handled-invoices-filters-modal__time-section">
              <TextInput
                label={t('economy:handled-invoices-filters-modal.to-created-time-label')}
                type="text"
                variant="default"
                validationKey="hourWithMinutes"
                placeholder={t('economy:handled-invoices-filters-modal.created-date-time-placeholder')}
                onChange={handleToCreatedTimeFilterChange}
                value={newFilters?.createdDate.to?.time}
                disabled={isBlank(newFilters?.createdDate.to?.date)}
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:handled-invoices-filters-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default HandledInvoicesFiltersModal;
