import { DrawerNavigation, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './handled-invoice-details-tab.scss';
import { HandledDetails } from 'src/types/HandledDetails';

interface HandledDetailsTabProps {
  onSelectHandledDetail: (id: string) => void;
  handledDetails: HandledDetails[];
}

const HandledDetailsTab: FC<HandledDetailsTabProps> = ({ onSelectHandledDetail, handledDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="handled-invoice-details-tab">
      <ListGroup data-testid="handled-detail" variant="drawer-navigation">
        {handledDetails.map((detail) => (
          <DrawerNavigation
            key={`HandledInvoiceDetailsTab-${detail.id}`}
            label={
              <Typography bold variant="body2" component="span">
                {t('economy:handled-invoices-drawer.handled-details-drawer-tab.note') +
                  ` ${String(detail.createdDate).substring(0, 10)}`}
              </Typography>
            }
            value={detail.note || t('economy:handled-invoices-drawer.no-note')}
            onClick={() => onSelectHandledDetail(detail.id)}
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default HandledDetailsTab;
