import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Invoice } from 'src/types/Invoice';
import { HandledDetails } from 'src/types/HandledDetails';
import { GET_HANDLED_DETAILS } from 'src/graphql/schema/query/GetHandledDetails';
import { HandledInvoiceDrawerTab } from 'src/types/tabs/HandledInvoiceDrawerTab';
import HandledInvoiceDetails from './HandledInvoiceDetails';
import HandledDetailsDrawerOverview from './HandledDetailsDrawerOverview';
import './handled-invoices-drawer.scss';
import { GET_REJECTION_DETAILS } from 'src/graphql/schema/query/GetRejectionDetails';
import { RejectionDetail } from 'src/types/RejectionDetail';
import RejectionDetails from '../../RejectedInvoicesTab/RejectiedInvoiceDrawer/RejectionDetails';

interface HandledInvoiceDrawerProps {
  invoice: Invoice;
  open: boolean;
  onClose: () => void;
}

const HandledInvoicesDrawer: FC<HandledInvoiceDrawerProps> = ({ invoice, open, onClose }) => {
  const [currentTab, setCurrentTab] = useState<HandledInvoiceDrawerTab>(HandledInvoiceDrawerTab.ABOUT);
  const [selectedHandledDetailId, setSelectedHandledDetailId] = useState<string | null>(null);
  const [selectedRejectedDetailId, setSelectedRejectedDetailId] = useState<string | null>(null);

  const handleSelectHandledDetail = (id: string) => {
    setSelectedHandledDetailId(id);
    setSelectedRejectedDetailId(undefined);
  };

  const handleSelectRejectedDetail = (id: string) => {
    setSelectedRejectedDetailId(id);
    setSelectedHandledDetailId(undefined);
  };

  const handleBackToRejectedDetails = () => {
    setSelectedRejectedDetailId(undefined);
  };

  const handleBackToHandledDetails = () => {
    setSelectedHandledDetailId(undefined);
  };
  const navigateToRejectedDetails = () => {
    setCurrentTab(HandledInvoiceDrawerTab.REJECTED);
    handleBackToRejectedDetails();
  };

  const navigateToHandledDetails = () => {
    setCurrentTab(HandledInvoiceDrawerTab.ABOUT);
    handleBackToHandledDetails();
  };

  return (
    <QueryWrapper query={GET_REJECTION_DETAILS} type="list" options={{ variables: { invoiceHeaderId: invoice?.id } }}>
      {(rejectionDetails: RejectionDetail[]) => (
        <DrawerModal
          overlayBackground
          overlayOnClose
          open={open}
          onClose={onClose}
          data-testid="handled-invoices-drawer"
        >
          <QueryWrapper
            query={GET_HANDLED_DETAILS}
            type="list"
            options={{ variables: { invoiceHeaderId: invoice?.id } }}
          >
            {(handledDetails: HandledDetails[]) => (
              <>
                {!selectedHandledDetailId && !selectedRejectedDetailId && (
                  <HandledDetailsDrawerOverview
                    handledDetails={handledDetails}
                    onClose={onClose}
                    onSelectedHandledDetails={handleSelectHandledDetail}
                    onSelectedRejectedDetails={handleSelectRejectedDetail}
                    currentTab={currentTab}
                    rejectionDetails={rejectionDetails}
                    invoice={invoice}
                  />
                )}
                {selectedHandledDetailId && !selectedRejectedDetailId && (
                  <HandledInvoiceDetails
                    invoice={invoice}
                    handledDetails={handledDetails.find((detail) => detail.id === selectedHandledDetailId)}
                    onClose={onClose}
                    onBackClick={navigateToHandledDetails}
                  />
                )}
                {!selectedHandledDetailId && selectedRejectedDetailId && (
                  <RejectionDetails
                    invoice={invoice}
                    rejectionDetail={rejectionDetails.find((detail) => detail.id === selectedRejectedDetailId)}
                    onClose={onClose}
                    onBackClick={navigateToRejectedDetails}
                  />
                )}
              </>
            )}
          </QueryWrapper>
        </DrawerModal>
      )}
    </QueryWrapper>
  );
};
export default HandledInvoicesDrawer;
