import { TableHead, TableBody, SortDirection } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { HandledInvoicesTableSortField } from './HandledInvoicesTableSortField';
import { Invoice } from 'src/types/Invoice';
import HandledInvoicesTableHead from './HandledInvoicesTableHead';
import './handled-invoices-table.scss';
import HandledInvoicesTableRow from './HandledInvoicesTableRow';
import HandledInvoicesDrawer from '../HandledInvoicesDrawer';

interface HandledInvoicesTableProps {
  invoices: Invoice[];
  sortingProperty?: HandledInvoicesTableSortField;
  sortDirection?: SortDirection;
  onRowClick: (id: string) => void;
  applySorting(property: string, direction: SortDirection);
}

const HandledInvoicesTable: FC<HandledInvoicesTableProps> = ({
  invoices,
  sortingProperty,
  sortDirection,
  applySorting,
}) => {
  const handleSort = (property: HandledInvoicesTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };

  const [selectedInvoice, setSelectedInvoice] = useState<string>(undefined);
  const handleOpenHandledInvoiceDrawer = (id: string) => {
    setSelectedInvoice(id);
  };

  const handleCloseHandledInvoiceDrawer = () => {
    setSelectedInvoice(undefined);
  };

  return (
    <>
      <div>
        <TableBase maxHeightPercentage={65}>
          <TableHead filled>
            <HandledInvoicesTableHead
              sortField={sortingProperty}
              sortDirection={sortDirection}
              onApplySorting={handleSort}
            />
          </TableHead>
          <TableBody>
            {invoices?.map((invoice, index) => {
              return (
                <HandledInvoicesTableRow
                  key={`handled-invoices-table-row-${invoice.id}-${index}`}
                  invoice={invoice}
                  onRowClick={(id) => handleOpenHandledInvoiceDrawer(id)}
                />
              );
            })}
          </TableBody>
        </TableBase>
      </div>

      {selectedInvoice !== undefined && (
        <HandledInvoicesDrawer
          open={!!selectedInvoice}
          onClose={handleCloseHandledInvoiceDrawer}
          invoice={invoices.find((invoice) => invoice.id === selectedInvoice)}
        />
      )}
    </>
  );
};

export default HandledInvoicesTable;
