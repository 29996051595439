import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import TemplateDrawerOverview from './TemplateDrawerOverview';
import SchedulingDetails from './TemplateDrawerOverview/SchedulingDetails';
import AddScheduling from './TemplateDrawerOverview/AddScheduling';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { TemplatesDrawerTab } from 'src/types/tabs/TemplatesDrawerTab';
import './template-drawer.scss';

interface TemplateDrawerProps {
  open: boolean;
  assignment: DocumentTemplateEmployerAssignment;
  customerId: string;
  onClose: () => void;
}

const TemplateDrawer: FC<TemplateDrawerProps> = ({ open, assignment, customerId, onClose }) => {
  const [currentTab, setCurrentTab] = useState<TemplatesDrawerTab>(TemplatesDrawerTab.ABOUT);
  const [addScheduling, setAddScheduling] = useState(false);
  const [selectedScheudulingId, setSelectedScheudulingId] = useState<string>(null);

  const handleAddScheduling = () => {
    setAddScheduling(true);
    setSelectedScheudulingId(undefined);
  };

  const handleSelectScheduling = (id: string) => {
    setSelectedScheudulingId(id);
    setAddScheduling(false);
  };

  const handleBackToScheduling = () => {
    setAddScheduling(false);
    setSelectedScheudulingId(undefined);
  };

  const navigateToScheduling = () => {
    setCurrentTab(TemplatesDrawerTab.SCHEDULING);
    handleBackToScheduling();
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose} data-testid="template-drawer">
      {!addScheduling && !selectedScheudulingId && (
        <TemplateDrawerOverview
          assignment={assignment}
          onClose={onClose}
          onAddScheduling={handleAddScheduling}
          onSelectScheduling={handleSelectScheduling}
          currentTab={currentTab}
        />
      )}
      {selectedScheudulingId && (
        <SchedulingDetails
          assignment={assignment}
          scheduling={assignment.scheduledJobs.find((option) => option.id === selectedScheudulingId)}
          onClose={onClose}
          onBackClick={navigateToScheduling}
        />
      )}
      {addScheduling && <AddScheduling assignment={assignment} customerId={customerId} onClose={onClose} onBackClick={navigateToScheduling} />}
    </DrawerModal>
  );
};

export default TemplateDrawer;
