import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import { createOptions, createOptionsFromList } from 'src/util/OptionUtil';
import TextInput from 'src/components/Common/TextInput';
import { PaymentsFilter } from 'src/types/PaymentsFilter';
import { isBlank } from 'src/util/StringUtil/StringUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import './search-payments-filters-modal.scss';
import { BankType, bankType } from 'src/types/BankType';
import { PaymentServiceProviderType, paymentServiceProviderType } from 'src/types/PaymentServiceProviderType';
import { PaymentStatus, paymentStatuses } from 'src/types/Payment';
import { Customer } from 'src/types/Customer';
import { Employer } from 'src/types/Employer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYERS_QUERY } from 'src/graphql/schema/query/GetEmployers';

interface SearchPaymentsFiltersModalProps {
  open: boolean;
  customers: Customer[];
  filters: PaymentsFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: PaymentsFilter) => void;
}

const SearchPaymentsFiltersModal: FC<SearchPaymentsFiltersModalProps> = ({
  open,
  customers,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<PaymentsFilter>({ ...filters });

  const handleBankFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      banks: options.map((option) => option.value),
    });
  };
  

  const handlePaymentStatusFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      paymentStatuses: options.length > 0 ? options.map((option) => option.value) : [],
    });
  };

  const handlePaymentServiceProviderFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      paymentServiceProviders: options.map((option) => option.value),
    });
  };

  const handleFromPaymentDueDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: value,
        toDate: newFilters.paymentDueDate.toDate,
      },
    });
  };

  const handleToPaymentDueDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: newFilters.paymentDueDate.fromDate,
        toDate: value,
      },
    });
  };

  const handleFromPaymentCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDate: {
        from: { ...newFilters.paymentCreatedDate.from, date: value },
        to: newFilters.paymentCreatedDate.to,
      },
    });
  };

  const handleToPaymentCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDate: {
        from: newFilters.paymentCreatedDate.from,
        to: { ...newFilters.paymentCreatedDate.to, date: value },
      },
    });
  };

  const handleToPaymentCreatedTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDate: {
        from: newFilters.paymentCreatedDate.from,
        to: { ...newFilters.paymentCreatedDate.to, time: input?.target.value },
      },
    });
  };

  const handleFromPaymentCreatedTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDate: {
        from: { ...newFilters.paymentCreatedDate.from, time: input?.target.value },
        to: newFilters.paymentCreatedDate.to,
      },
    });
  };


  const handleCustomersFilterChange = (option: Option<string>) => {
    console.log('lkajdsakjdslkfaslkfhaskjdfhlaskdjhlasasdfasdfasdfs');

    setNewFilters({
      ...newFilters,
      customerId: option.value,
    });
  };

  const handleEmployersFilterChange = (option: Option<string>) => {
    setNewFilters({
      ...newFilters,
      employerId: option.value,
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const bankOptions: Option<BankType>[] = createOptions(bankType, t, 'payment:bank');
  const paymentStatusOptions: Option<PaymentStatus>[] = createOptions(paymentStatuses, t, 'payment:payment-status');
  const customerOptions: Option<string>[] = createOptionsFromList(customers, 'id', 'name');
  const paymentServiceProviderOptions: Option<PaymentServiceProviderType>[] = createOptions(
    paymentServiceProviderType,
    t,
    'payment:payment-service-provider',
  );

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-payments-filters-modal"
        title={t('economy:search-payments-filters-modal.title')}
        icon={<Icon type="Sliders" className="search-payments-filters-modal__icon" />}
        size="wide"
      >
        <div className="search-payments-filters-modal__filters">
          <div>
            <div className="search-payments-filters-modal__filters__row-container">
              <div className="search-payments-filters-modal__select">
                <Select
                  label={t('economy:search-payments-filters-modal.customer-label')}
                  placeholder={t('common:all')}
                  options={customerOptions}
                  selected={customerOptions.find((option) => option.value === newFilters.customerId)}
                  onChange={handleCustomersFilterChange}
                  small
                  outlined
                />
              </div>

              {newFilters?.customerId && (
                <QueryWrapper
                  query={GET_EMPLOYERS_QUERY}
                  options={{ skip: !newFilters.customerId, variables: { customerId: newFilters.customerId } }}
                >
                  {(employers: Employer[]) => {
                    const employerOptions = createOptionsFromList(employers, 'id', 'legalName');
                    return (
                      <div className="search-payments-filters-modal__select">
                        <Select
                          label={t('economy:search-payments-filters-modal.employer-label')}
                          placeholder={t('common:all')}
                          options={employerOptions}
                          selected={employerOptions.find((option) => option.value === newFilters.employerId)}
                          onChange={handleEmployersFilterChange}
                          small
                          outlined
                        />
                      </div>
                    );
                  }}
                </QueryWrapper>
              )}
            </div>

            <div className="search-payments-filters-modal__filters__row-container">
              <div className="search-payments-filters-modal__select">
                <Select
                  label={t('economy:search-payments-filters-modal.payment-status-label')}
                  placeholder={t('common:all')}
                  selectAll
                  options={paymentStatusOptions}
                  selected={paymentStatusOptions.filter((option) =>
                    newFilters?.paymentStatuses?.includes(option.value),
                  )}
                  onChange={handlePaymentStatusFilterChange}
                  small
                  outlined
                  multiple
                />
              </div>
              <div className="search-payments-filters-modal__select">
                <Select
                  label={t('economy:search-payments-filters-modal.bank-label')}
                  placeholder={t('common:all')}
                  selectAll
                  options={bankOptions}
                  selected={bankOptions.filter((option) => newFilters?.banks?.includes(option.value))}
                  onChange={handleBankFilterChange}
                  small
                  outlined
                  multiple
                />
              </div>
              <div className="search-payments-filters-modal__select">
                <Select
                  label={t('economy:search-payments-filters-modal.payment-service-provider-label')}
                  placeholder={t('common:all')}
                  selectAll
                  options={paymentServiceProviderOptions}
                  selected={paymentServiceProviderOptions.filter((option) =>
                    newFilters?.paymentServiceProviders?.includes(option.value),
                  )}
                  onChange={handlePaymentServiceProviderFilterChange}
                  small
                  outlined
                  multiple
                />
              </div>
            </div>
            <div className="search-payments-filters-modal__filters__row-container">
              <div className="search-payments-filters-modal__date">
                <UpdateDatePicker
                  label={t('economy:search-payments-filters-modal.from-payment-due-date-label')}
                  fieldValue={newFilters?.paymentDueDate?.fromDate.toString() ?? ''}
                  fieldKey="paymentDueDate"
                  onBlur={handleFromPaymentDueDateFilterChange}
                />
              </div>
              <div className="search-payments-filters-modal__date">
                <UpdateDatePicker
                  label={t('economy:search-payments-filters-modal.to-payment-due-date-label')}
                  fieldValue={newFilters?.paymentDueDate?.toDate.toString() ?? ''}
                  fieldKey="paymentDueDate"
                  onBlur={handleToPaymentDueDateFilterChange}
                />
              </div>
            </div>
            <div className="search-payments-filters-modal__date-time-interval">
              <div className="search-payments-filters-modal__date-section">
                <div className="search-payments-filters-modal__date-time-interval">
                  <div>
                    <UpdateDatePicker
                      label={t('economy:search-payments-filters-modal.from-payment-created-date-label')}
                      fieldValue={newFilters?.paymentCreatedDate?.from?.date?.toString()}
                      fieldKey="createdDate"
                      onBlur={handleFromPaymentCreatedDateFilterChange}
                    />
                  </div>
                  <TextInput
                    label={t('economy:search-payments-filters-modal.from-payment-created-time-label')}
                    type="text"
                    variant="default"
                    validationKey="hourWithMinutes"
                    placeholder={t('economy:search-payments-filters-modal.payment-created-date-time-placeholder')}
                    onChange={handleFromPaymentCreatedTimeFilterChange}
                    value={newFilters?.paymentCreatedDate.from?.time}
                    disabled={isBlank(newFilters?.paymentCreatedDate.from?.date)}
                    maxLength={5}
                  />
                </div>
              </div>
              <div className="search-payments-filters-modal__date-section">
                <div className="search-payments-filters-modal__date-time-interval">
                  <div>
                    <UpdateDatePicker
                      label={t('economy:search-payments-filters-modal.to-payment-created-date-label')}
                      fieldValue={newFilters?.paymentCreatedDate?.to?.date?.toString() ?? ''}
                      fieldKey="createdDate"
                      onBlur={handleToPaymentCreatedDateFilterChange}
                    />
                  </div>
                  <TextInput
                    label={t('economy:search-payments-filters-modal.to-payment-created-time-label')}
                    type="text"
                    variant="default"
                    validationKey="hourWithMinutes"
                    placeholder={t('economy:search-payments-filters-modal.payment-created-date-time-placeholder')}
                    onChange={handleToPaymentCreatedTimeFilterChange}
                    value={newFilters?.paymentCreatedDate.to?.time}
                    disabled={isBlank(newFilters?.paymentCreatedDate.to?.date)}
                    maxLength={5}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:search-payments-filters-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchPaymentsFiltersModal;
