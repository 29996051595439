import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';

interface RejectedInvoicesTableHeadsProps {
  sortDirection: SortDirection;
  sortField: string;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: string) => void;
}

const RejectedInvoicesTableHeads: FC<RejectedInvoicesTableHeadsProps> = ({
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t(''),
      fieldName: 'checkbox',
      includeSortButtons: false,
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.employer'),
      sortDirection,
      fieldName: 'employer',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('employer');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.file-name'),
      sortDirection,
      fieldName: 'fileName',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('fileName');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.insurance-provider'),
      sortDirection,
      fieldName: 'insuranceProvider',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('insuranceProvider');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.account-to'),
      sortDirection,
      fieldName: 'accountNumberTo',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('accountNumberTo');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('invoiceNumber');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.amount'),
      sortDirection,
      fieldName: 'totalAmount',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('totalAmount');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-type-code'),
      sortDirection,
      fieldName: 'invoiceTypeCode',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('invoiceTypeCode');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-due-date'),
      sortDirection,
      fieldName: 'dueDate',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('dueDate');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-created-date'),
      sortDirection,
      fieldName: 'createdDate',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('createdDate');
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`RejectedInvoicesTableHeads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default RejectedInvoicesTableHeads;
