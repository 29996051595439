import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { RejectedInvoicesTableSortField } from './RejectedInvoicesTableSortField';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import { Invoice } from 'src/types/Invoice';
import RejectedInvoicesTableRow from './RejectedInvoicesTableRow';
import RejectedInvoicesTableHeads from './RejectedInvoicesTableHeads';
import './rejected-invoices-table.scss';
import RejectedInvoiceDrawer from '../RejectiedInvoiceDrawer';

interface RejectedInvoicesTableProps {
  invoices: Invoice[];
  checked: string[];
  onChange: (id: string) => void;
  onRowClick: (id: string) => void;
}

const RejectedInvoicesTable: FC<RejectedInvoicesTableProps> = ({ invoices, checked, onChange, onRowClick }) => {
  const [sortField, setSortField] = useState<RejectedInvoicesTableSortField>('employer');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');
  const [selectedInvoice, setSelectedInvoice] = useState<string>(undefined);
  const handleOpenRejectedInvoiceDrawer = (id: string) => {
    setSelectedInvoice(id);
  };

  const handleCloseRejectedInvoiceDrawer = () => {
    setSelectedInvoice(undefined);
  };
  const handleSortFieldChange = (sortField: RejectedInvoicesTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const sortFunction = (a: Invoice, b: Invoice) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;

    switch (sortField) {
      case 'employer':
        return sortAlphabetical(a.employer?.legalName, b.employer?.legalName);
      case 'fileName':
        return sortAlphabetical(a.fileName, b.fileName);
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'accountNumberTo':
        return sortAlphabetical(a.accountNumberTo, b.accountNumberTo);
      case 'invoiceNumber':
        return sortAlphabetical(a.invoiceNumber, b.invoiceNumber);
      case 'totalAmount':
        return sortNumerical(a.totalAmount, b.totalAmount);
      case 'invoiceTypeCode':
        return sortAlphabetical(a.invoiceTypeCode, b.invoiceTypeCode);
      case 'dueDate':
        return sortDate(a.dueDate, b.dueDate);
      case 'createdDate':
        return sortDate(a.createdDate, b.createdDate);
      default:
        return undefined;
    }
  };

  const sortedInvoices = [...invoices].sort(sortFunction) || [];

  return (
    <>
      <div className="rejected-invoices-table">
        <TableBase compressed>
          <TableHead filled>
            <RejectedInvoicesTableHeads
              sortDirection={sortDirection}
              sortField={sortField}
              onSortDirectionChange={handleSortDirectionChange}
              onSortFieldChange={handleSortFieldChange}
            />
          </TableHead>
          <TableBody>
            {sortedInvoices.map((invoice) => (
              <RejectedInvoicesTableRow
                key={`RejectedInvoicesTableRow-${invoice.id}`}
                invoice={invoice}
                onRowClick={(id) => handleOpenRejectedInvoiceDrawer(id)}
                onChange={() => onChange(invoice.id)}
                checked={checked.includes(invoice.id)}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>

      {selectedInvoice !== undefined && (
        <RejectedInvoiceDrawer
          open={!!selectedInvoice}
          onClose={handleCloseRejectedInvoiceDrawer}
          invoice={invoices.find((invoice) => invoice.id === selectedInvoice)}
        />
      )}
    </>
  );
};

export default RejectedInvoicesTable;
