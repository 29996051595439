import { useApolloMutation } from './graphql/Mutation';
import { PATCH_INVOICE_HEADER_STATUS } from 'src/graphql/schema/mutation/PatchInvoiceHeader';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/SearchInvoices';
import { SEARCH_REJECTED_INVOICES } from 'src/graphql/schema/query/SearchRejectedInvoices';
import { PatchInvoiceHeader } from 'src/types/request/PatchInvoiceHeader';

export const usePatchInvoiceHeader = () => {
  const apolloMutation = useApolloMutation();

  const patchInvoiceHeader = (request: PatchInvoiceHeader[], employerId: string) =>
    apolloMutation(PATCH_INVOICE_HEADER_STATUS, employerId, { request }, [SEARCH_INVOICES, SEARCH_REJECTED_INVOICES]);

  return patchInvoiceHeader;
};
