import React, { FC, useMemo, useState } from 'react';
import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Paging } from 'src/types/PageableResponse';
import HandledInvoicesFilterDetails from '../HandledInvoicesFilterDetails';
import HandledInvoicesFiltersModal from '../HandledInvoicesFiltersModal';
import HandledInvoicesTable from '../HandledInvoicesTable';
import { HandledInvoicesTableSortField } from '../HandledInvoicesTable/HandledInvoicesTableSortField';
import SearchInput from '../../SearchInput';
import HandledInvoicesDrawer from '../HandledInvoicesDrawer';
import { SearchHandledInvoicesFilter } from 'src/types/invoice/SearchHandledInvoicesFilter';

interface HandledInvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  sortProperty?: HandledInvoicesTableSortField;
  sortDirection: SortDirection;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInvoicesFilter: SearchHandledInvoicesFilter;
  searchTextInput: string;
  searchAmountInput: string;
  onTextSearch: (searchInput: string) => void;
  onAmountSearch: (amountSearch: string) => void;
  onApplyFilters: (filters: SearchHandledInvoicesFilter) => void;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onPageChange: (pageSize: number) => void;
}

const HandledInvoices: FC<HandledInvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  sortProperty,
  sortDirection,
  searchInvoicesFilter,
  searchTextInput,
  searchAmountInput,
  onTextSearch,
  onAmountSearch,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [selectedHandledInvoiceId, setSelectedHandledInvoiceId] = useState<string | undefined>();
  const handleRowClick = (id: string) => {
    setSelectedHandledInvoiceId(id);
  };

  const closeDrawer = () => {
    setSelectedHandledInvoiceId(null);
  };

  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );

  const handleModalAction = () => setFiltersModalOpen(!filtersModalOpen);

  return (
    <>
      <div className="handled-invoices-tab">
        <div className="handled-invoices-tab__search-container">
          <div className="handled-invoices-tab__search-container__input">
            <SearchInput
              onSearch={onTextSearch}
              searchInput={searchTextInput}
              placeHolder={t('economy:handled-invoices-tab.filters.search-placeholder')}
              type={'text'}
            />
          </div>
          <div className="handled-invoices-tab__search-container__input">
            <SearchInput
              onSearch={onAmountSearch}
              searchInput={searchAmountInput}
              placeHolder={t('economy:handled-invoices-tab.filters.search-placeholder-amount')}
              type={'number'}
            />
          </div>
        </div>
        <HandledInvoicesFilterDetails filters={searchInvoicesFilter} applyFilters={onApplyFilters} />
        <HandledInvoicesTable
          invoices={invoices}
          onRowClick={handleRowClick}
          applySorting={onApplySorting}
          sortingProperty={sortProperty}
          sortDirection={sortDirection}
        />
        {filtersModalOpen && (
          <HandledInvoicesFiltersModal
            open={filtersModalOpen}
            onClose={handleModalAction}
            onApplyFilters={onApplyFilters}
            filters={searchInvoicesFilter}
          />
        )}
        {totalPages > 1 && <Pagination currentPage={pageNumber} onPageChange={onPageChange} totalPages={totalPages} />}
        <div className="handled-invoices-tab__filter-button">
          <Button
            type="floating-action-button"
            icon={<Icon type="Sliders" stroke="white" />}
            label={t('common:filter')}
            onClick={handleModalAction}
            data-cy="filter-button"
          />
        </div>
      </div>

      {selectedHandledInvoiceId && (
        <HandledInvoicesDrawer
          open={!!selectedHandledInvoiceId}
          onClose={closeDrawer}
          invoice={invoices.find((invoice) => invoice.id === selectedHandledInvoiceId)}
        />
      )}
    </>
  );
};

export default HandledInvoices;
