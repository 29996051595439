import React, { ChangeEvent, FC, useState } from 'react';
import { Modal, Button, Typography, Card, CardAction, Icon } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { isBlank } from 'src/util/StringUtil/StringUtil';

interface RejectedInvoicesConfirmModalProps {
  open: boolean;
  onConfirm: (note: string) => void;
  onClose: () => void;
}

const RejectedInvoicesConfirmModal: FC<RejectedInvoicesConfirmModalProps> = ({ open, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [note, setNote] = useState('');

  const handleConfirm = () => {
    onConfirm(note);
    setNote('');
  };

  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const isConfirmDisabled = isBlank(note) || note.length < 3;

  return (
    <Modal data-cy="rejected-invoices-confirm-modal" centered overlayBackground open={open} onClose={onClose}>
      <Card
        icon={<Icon type="Alert" stroke="#fda522" />}
        title={t('economy:rejected-invoices-modal.handle-rejected-invoices')}
        defaultClose
        onClose={onClose}
      >
        <Typography component="span">{t('economy:rejected-invoices-tab.confirm-handle')}</Typography>
        <TextArea
          value={note}
          onChange={handleNoteInput}
          placeholder={t('economy:rejected-invoices-modal.note')}
          characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
          validationKey="text255"
        />
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            data-cy="rejected-invoices-confirm-modal__cancel-button"
            onClick={onClose}
          />
          <Button
            label={t('economy:rejected-invoices-modal.handle')}
            onClick={handleConfirm}
            disabled={isConfirmDisabled}
            data-cy="rejected-invoices-confirm-modal__confirm-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default RejectedInvoicesConfirmModal;
