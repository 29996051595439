import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import HandledInvoices from './HandledInvoice';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import LoadingPage from 'src/components/Common/LoadingPage';
import { SearchInvoicesPage } from 'src/types/Invoice';
import { HandledInvoicesTableSortField } from './HandledInvoicesTable/HandledInvoicesTableSortField';
import { EXTERNAL_SEARCH_INVOICES } from 'src/graphql/schema/query/external/SearchInvoices';
import { SortDirection } from 'fgirot-k2-ui-components';
import { SearchHandledInvoicesFilter } from 'src/types/invoice/SearchHandledInvoicesFilter';
import { getDefaultHandledInvoicesFilters } from '../RejectedInvoicesTab/utils/HandledInvoiceUtils';
import './handled-invoices-tab.scss';
import { DateTime } from 'luxon';

const HandledInvoicesTab = () => {
  const [invoicesFilter, setInvoicesFilter] = useState<SearchHandledInvoicesFilter>(getDefaultHandledInvoicesFilters());
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchAmountInput, setSearchAmountInput] = useState<string>('');

  const [sort, setSort] = useState<{ property?: HandledInvoicesTableSortField; direction: SortDirection }>({
    property: 'invoiceDueDate',
    direction: 'DOWN',
  });

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleTextSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  const handleSearchAmount = (searchAmount: string) => {
    if (!isNaN(Number(searchAmount))) {
      setSearchAmountInput(searchAmount);
    }
  };

  const handleApplyFilters = (filters: SearchHandledInvoicesFilter) => {
    setInvoicesFilter(filters);
  };

  const handleSortingChange = (property: HandledInvoicesTableSortField, sortDirection: SortDirection) => {
    setSort({ property: property, direction: sortDirection });
  };

  const getCreatedDate = (date: string, time: string, defaultTime: string) => {
    if (date == undefined || date == '') {
      return undefined;
    }
    const t = time != undefined && time != '' ? time : defaultTime;

    const dateInUtc = DateTime.fromISO(date + 'T' + t, { zone: 'Europe/Stockholm' })
      .setLocale('sv-SE')
      .setZone('utc')
      .toISO({ includeOffset: false });

    return dateInUtc;
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    sorting: sort && [{ property: sort.property.toString(), direction: sort.direction == 'UP' ? 'ASC' : 'DESC' }],
    searchInputs: [
      searchInput?.length > 0 && {
        properties: ['invoiceNumber', 'ocrNumber'],
        value: searchInput,
        exactMatch: false,
      },
      searchAmountInput && {
        properties: ['totalAmount'],
        value: searchAmountInput,
        exactMatch: true,
      },
    ].filter((search) => search && search.value != ''),
    filters: [
      {
        property: 'invoiceDueDate',
        values: invoicesFilter?.invoiceDueDate?.fromDate,
        condition: 'GE',
      },
      {
        property: 'invoiceDueDate',
        values: invoicesFilter?.invoiceDueDate?.toDate,
        condition: 'LE',
      },
      {
        property: 'invoiceStatus',
        values: invoicesFilter?.invoiceStatuses,
        condition: 'IN',
      },
      {
        property: 'createdDate',
        values: getCreatedDate(
          invoicesFilter?.createdDate?.from?.date,
          invoicesFilter?.createdDate?.from?.time,
          '00:00',
        ),
        condition: 'GE',
      },
      {
        property: 'createdDate',
        values: getCreatedDate(invoicesFilter?.createdDate?.to?.date, invoicesFilter?.createdDate?.to?.time, '23:59'),
        condition: 'LE',
      },
    ].filter((filter) => filter.values && filter.values.length > 0),
  };
  return (
    <QueryWrapper
      query={EXTERNAL_SEARCH_INVOICES}
      loadingComponent={<LoadingPage />}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ searchInvoices, paging }: SearchInvoicesPage) => (
        <HandledInvoices
          invoices={searchInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sortProperty={sort?.property}
          sortDirection={sort.direction}
          searchInvoicesFilter={invoicesFilter}
          searchTextInput={searchInput}
          searchAmountInput={searchAmountInput?.toString()}
          onTextSearch={handleTextSearch}
          onAmountSearch={handleSearchAmount}
          onApplyFilters={handleApplyFilters}
          onApplySorting={handleSortingChange}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default HandledInvoicesTab;
