import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import { Invoice } from 'src/types/Invoice';
import { HandledDetails } from 'src/types/HandledDetails';

interface HandledInvoiceDetailsProps {
  invoice: Invoice;
  handledDetails: HandledDetails;
  onClose: () => void;
  onBackClick: () => void;
}

const HandledInvoiceDetails: FC<HandledInvoiceDetailsProps> = ({ invoice, handledDetails, onClose, onBackClick }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="handled-invoices-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('economy:handled-invoices-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="handled-invoices-drawer__sub-title">
          <Typography>{invoice.employer.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('economy:handled-invoices-drawer.handled-details-drawer-tab.handled-detail-label')}
          value={String(handledDetails.createdDate).substring(0, 10)}
        />
        <ListProperty
          label={t('economy:handled-invoices-drawer.handled-details-drawer-tab.note')}
          value={stringOrBlank(handledDetails.note)}
        />
      </ListGroup>
    </Drawer>
  );
};

export default HandledInvoiceDetails;
